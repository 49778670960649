
<template>
  <div>
    <user-email-verification v-if="activeUser && !activeUser.is_verified" />

    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="pendingOrders.analyticsData"
          icon="CoffeeIcon"
          :hideChart="true"
          :statistic="pendingOrders.analyticsData.total | k_formatter"
          statisticTitle="Pending Orders"
          color='success'
          type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="deliveredOrders.analyticsData"
          icon="ShoppingCartIcon"
          :hideChart="true"
          :statistic="deliveredOrders.analyticsData.total"
          statisticTitle="Delivered Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="completedOrders.analyticsData"
          icon="AwardIcon"
          :hideChart="true"
          :statistic="completedOrders.analyticsData.total | k_formatter"
          statisticTitle="Completed Orders"
          color='warning'
          type='area' />
      </div>
      <!-- <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          v-if="refundedOrders.analyticsData"
          icon="CompassIcon"
          :hideChart="true"
          :statistic="refundedOrders.analyticsData.total | k_formatter"
          statisticTitle="Offers"
          type='area' />
      </div> -->
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-success">
          <div class="vs-alert sm:px-4">
            <h4 class="titlex h3 mb-2 shadow-none text-success">Are you ready to start a new project?</h4>
            <span class="mb-2">Click this link to begin a new project right away </span>
            <div class="py-4">
              <a :href="absoluteUrl('/categories')" target="_blank" name="button" class="vs-component vs-button vs-button-success vs-button-border" style="background: transparent;">
                <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                <span class="vs-button-text vs-button--text">Categories</span><span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <order-list-table />
      </div>
    </div>
  </div>
</template>

<script>

import OrderListTable from '@/components/order/OrderListTable.vue'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import UserEmailVerification    from "./account/UserEmailVerification.vue"

export default{
    components: {
      StatisticsCardLine,
      UserEmailVerification,
      OrderListTable,
    },
    data() {
      return {
        refundedOrders: {
          analyticsData: {
            total: 0
          }
        },
        pendingOrders: {
          analyticsData: {
            total: 0
          }
        },
        deliveredOrders: {
          analyticsData: {
            total: 0
          }
        },
        completedOrders:  {
          analyticsData: {
            total: 0
          }
        }
      }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          const {result} = response.data.data
          this.refundedOrders.analyticsData.total = result.orders.refunded
          this.pendingOrders.analyticsData.total = result.orders.pending
          this.deliveredOrders.analyticsData.total = result.orders.delivered
          this.completedOrders.analyticsData.total = result.orders.completed
        }
      })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}

.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
